import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styled from 'styled-components';
import Logo from 'images/RECH2022BrandWhite.svg';
import Container from '@material-ui/core/Container';

const styles = theme => ({
  wrapper: {
    height: '100vh',
  },
  logo: {
    width: 'auto',
    height: '250px',
  },
  leftDivider: {
    width: '2px',
    backgroundColor: 'white',
    height: 'auto',
  },
});

export const Background = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

function HomeHero(props) {
  const { data, classes } = props;
  return (
    <Background image={data.node.banner_image.url}>
      <Box width="100%" height="100%" display="flex" alignItems="center">
        <Container maxWidth="xl">
          <Box display="flex">
            <Box
              className={classes.leftDivider}
              mr={10}
              display={{ xs: 'none', md: 'block' }}
            />
            <Logo className={classes.logo} />
          </Box>
        </Container>
      </Box>
    </Background>
  );
}

HomeHero.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeHero);
